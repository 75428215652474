import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { LoadingScreen } from '@mesa-labs/mesa-ui';
import { isRejected } from '@reduxjs/toolkit';
import * as Sentry from '@sentry/react';

import { FeeScheme } from '@mesa-labs/mesa-api/dist/types';
import { useDispatch, useSelector } from '../../redux/hooks';
import { setupCognito, signIn, updateEmail, updateExternalVendorId } from '../../redux/slices/auth';
import {
  updateAvailablePrograms,
  updateCustomMsa, updateFeeScheme, updateOnboardingId, updatePartnerId,
  updateSkipAutoInstantPayoutStep,
} from '../../redux/slices/onboarding';
import { updateVendorField } from '../../redux/slices/vendor';
import { useLazyValidateOnboardingIdQuery } from '../../redux/api/vendor';

type AuthRouteProps = {
  children: React.ReactElement,
  redirectToSignIn?: boolean,
  saveQueryStringData?: boolean,
};

function AuthRoute({
  children,
  redirectToSignIn = true,
  saveQueryStringData = false,
}: AuthRouteProps): React.ReactElement {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);
  const vendorId = useSelector((state) => state.auth.vendorId);
  const [ready, setReady] = useState(false);
  const demoModeEmail = useMemo(() => {
    const params = new URLSearchParams(window.location.search);
    const hasDemoModeParam = params.has('demo');
    const demoModeParam = params.get('demo');

    if (hasDemoModeParam) {
      switch (demoModeParam) {
        case 'cbre':
          return 'boulder+hvac+cbre@joinmesa.com';
        default:
          return 'test-integration+4860174@joinmesa.com';
      }
    }

  }, [window.location.search]);

  const [validateOnboardingId] = useLazyValidateOnboardingIdQuery();

  // set vendorId on the Sentry user
  useEffect(() => {
    if (vendorId) {
      Sentry.setUser({ vendorId });
    }
  }, [vendorId]);

  useEffect(() => {
    (async () => {
      // save query string data if specified
      if (!saveQueryStringData) {
        return;
      }

      const searchParams = new URLSearchParams(window.location.search);
      const onboardingId = searchParams.get('onboardingId');

      // validate and update data from onboardingId, if applicable
      if (onboardingId) {
        const result = await validateOnboardingId({ onboardingId });

        if (result.isSuccess) {
          dispatch(updateOnboardingId(onboardingId));
          dispatch(updateExternalVendorId(result.data?.externalVendorId));
          dispatch(updatePartnerId(result.data?.partnerId));
          dispatch(updateFeeScheme(result.data?.feeScheme || FeeScheme.DISCOUNTING));
          dispatch(updateCustomMsa(result.data?.customMsa || false));
          dispatch(updateSkipAutoInstantPayoutStep(result.data?.skipAutoInstantPayoutStep || false));
          dispatch(updateAvailablePrograms(result.data?.availablePrograms));

          dispatch(updateVendorField({ field: 'onboardingId', value: onboardingId }));
          dispatch(updateVendorField({ field: 'name', value: result.data?.vendorName }));
          dispatch(updateVendorField({ field: 'email', value: result.data?.email }));
        }
      }

      // update data from query strings and override onboarding data
      const email = searchParams.get('email');
      const businessName = searchParams.get('businessName');

      if (email) {
        dispatch(updateEmail(email));
        dispatch(updateVendorField({ field: 'email', value: email }));
      }

      if (businessName) {
        dispatch(updateVendorField({ field: 'name', value: businessName }));
      }
    })();
  }, [saveQueryStringData]);

  // auth setup and listeners
  useEffect(() => {
    (async () => {
      const result = await dispatch(setupCognito());

      if (isRejected(result) && redirectToSignIn) {
        navigate('/sign-in', { replace: true });
        return;
      }

      setReady(true);
    })();
  }, []);

  // redirect to appropriate route based on current auth state
  useEffect(() => {
    if (!ready) {
      return;
    }

    if (demoModeEmail) {
      dispatch(signIn({ email: demoModeEmail, password: 'TestAccount1!', totpCallback: () => Promise.resolve('') }));
      navigate('/onboarding/business-info');
      return;
    }

    if (!redirectToSignIn) {
      // vendor is considered "onboarded"
      if (isLoggedIn && vendorId) {
        navigate('/');
      } else if (isLoggedIn) {
        navigate('/onboarding/business-info');
      }

      return;
    }

    if (!isLoggedIn) {
      navigate('/sign-in' + (window.location.pathname !== '/sign-in' ? '?redirect=' + encodeURIComponent(window.location.pathname) : ''), { replace: true });
    }
  }, [ready, isLoggedIn]);

  return ready ? children : <LoadingScreen />;
}

AuthRoute.defaultProps = {
  redirectToSignIn: true,
  saveQueryStringData: false,
};

export default AuthRoute;
