import { types as api } from '@mesa-labs/mesa-api';

export { default as localStorage } from './localStorage';

export const toFormattedAmount = (amount: number) => new Intl.NumberFormat(undefined, { minimumFractionDigits: 2 }).format(amount);

export const toPercent = (amount: number, minimumFractionDigits = 2, maximumFractionDigits = 2) =>
  new Intl.NumberFormat(undefined, { style: 'percent', minimumFractionDigits, maximumFractionDigits }).format(amount);

export function copyToClipboard(text: string, cb = () => { }) {
  if (navigator.clipboard && window.isSecureContext) {
    cb();
    return navigator.clipboard.writeText(text);
  } else {
    // Fallback method for older browsers
    const textArea = document.createElement("textarea");
    textArea.value = text;
    textArea.style.position = "fixed";
    textArea.style.opacity = "0";
    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();

    try {
      document.execCommand("copy");
    } finally {
      document.body.removeChild(textArea);
      cb();
    }
  }
}

export const asExportFilter = <T>(filter: T): T => ({
  ...filter,
  page: 1,
  limit: Number.MAX_SAFE_INTEGER,
});

export type DisplayableProgram = {
  readonly programCode: api.ProgramCodes;
  readonly netDays?: number;
  readonly discountRate: number;
  readonly supplementalRate?: number;
  readonly pricingTiers?: readonly api.PricingTier[];
}

export function getDisplayablePrograms(programs: readonly api.MaybeRedactedProgramResponse[], availablePrograms: readonly api.OnboardingProgramOverrideResponse[] | undefined): readonly DisplayableProgram[] {
  return availablePrograms?.map((override) => ({
    ...override,
    netDays: programs?.find(p => p.programCode === override.programCode)?.netDays,
  })) || programs.filter((program) => program.visibleInOnboarding);
}